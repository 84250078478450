import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { theme } from '@huspy/forge';
import { useAuthStore } from '@modules/core/store';
import { useTranslation } from 'react-i18next';
import useUploadFileToS3 from '@modules/documents/hooks/mutations/useUploadFileToS3';
import {
  Document,
  DocumentCategory,
  RequiredDocument,
  RequiredDocuments,
} from '@modules/documents/api/types';

import { Text } from '@huspy/briks-web';
import useGetUploadPreSignedUrls from '@modules/documents/hooks/mutations/useGetUploadPreSignedUrls';
import { documentUploadModalStyles } from '../DocumentUploadModal/styles/index.css';
import {
  FileToUpload,
  FilesToUpload,
  MAX_FILE_SIZE,
} from '../DocumentUploadModal/UploadDropzone';
import { RejectedDocuments } from './RejectedDocuments';
import { UploadLoader } from './UploadLoader';
import { pdfFileHasPassword } from './pdfUtils';
import { BulkUploadZone } from './BulkUploadZone';
import { CategorizeDocuments } from './CategorizeDocuments';

const { headerRoot } = documentUploadModalStyles;

export type DocumentUploadModalRef =
  | { open(): void; close(): void }
  | undefined;

type Props = {
  applicantId: string;
  documents: RequiredDocuments;
  uploadingFiles: FilesToUpload;
  setUploadingFiles: Dispatch<SetStateAction<FilesToUpload>>;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  acceptedDocuments: Document[];
};

const getDocumentTypes = (documents: RequiredDocuments) => {
  const categoriesList = Object.keys(documents) as DocumentCategory[];
  return categoriesList
    .filter((item) => item !== 'uncategorized')
    .flatMap((category) => documents[category] as unknown as RequiredDocument[]);
};

const isValidDocument = async (formDataFile: File) => {
  if (formDataFile.size > MAX_FILE_SIZE) {
    return [false, 'size'];
  }

  if (formDataFile.type === 'application/pdf') {
    try {
      const hasPassword = await pdfFileHasPassword(formDataFile);
      if (hasPassword) {
        return [false, 'password'];
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  return [true, null];
};

// eslint-disable-next-line react/display-name
const BulkUploadModal = forwardRef<DocumentUploadModalRef, Props>(
  (
    {
      applicantId,
      documents,
      uploadingFiles,
      setUploadingFiles,
      step,
      setStep,
      acceptedDocuments,
    },
    ref
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const [opened, { open, close }] = useDisclosure(false);
    const { oppId } = useAuthStore();
    const { t } = useTranslation();
    const { mutateAsync: getGetUploadPresignedUrls } = useGetUploadPreSignedUrls(oppId!, applicantId);
    const { mutateAsync: uploadFileWithPresignedUrl } = useUploadFileToS3(
      oppId!,
      applicantId
    );
    const [numberOfDocs, setNumOfDocs] = useState(0);
    const [numUploadedDoc, setNumUploadedDoc] = useState(0);

    useImperativeHandle(ref, () => ({ open, close }), [open, close]);

    const onClose = () => {
      close();
      setNumOfDocs(0);
      setNumUploadedDoc(0);
      setStep(0);
    };

    const uploadDocumentToS3 = async (files: FilesToUpload) => {
      if (files.length === 0) return;
      try {
        const data = await getGetUploadPresignedUrls({
          opportunityExternalID: oppId!,
          opportunityApplicantExternalID: applicantId,
          filesNames: files.map((file) => file.fileName),
        });
        await Promise.all(
          data.response.map(async (res, idx) => {
            const file = files[idx];
            await uploadFileWithPresignedUrl({
              url: res.url,
              fields: res.fields,
              opportunityExternalID: oppId!,
              opportunityApplicantExternalID: applicantId,
              body: file?.body!,
            }).then(() => setNumUploadedDoc((prev) => prev + 1));
          })
        );
      } catch {
        onClose();
        throw new Error('Error while uploading the document');
      }
    };

    const documentsType = getDocumentTypes(documents);

    const uploadAllDocuments = async (files: FilesToUpload) => {
      const acceptedFiles = files.filter(
        (item) => !(item.isRejected && !item.isResolved)
      );
      setNumOfDocs(acceptedFiles.length);
      setStep(1);
      await uploadDocumentToS3(acceptedFiles);
      setStep(3);
      setUploadingFiles([]);
    };

    const handleDocuments = async (files: FilesToUpload) => {
      const filesToUpload: FilesToUpload = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const formDataFile = file.body.get('document') as File;

        // eslint-disable-next-line no-await-in-loop
        const [isValid, reason] = await isValidDocument(formDataFile);

        if (isValid) {
          filesToUpload.push({ ...file, isRejected: false });
        }
        if (!isValid && reason === 'size') {
          filesToUpload.push({
            ...file,
            rejectionReason: 'size',
            isRejected: true,
          });
        }
        if (!isValid && reason === 'password') {
          filesToUpload.push({
            ...file,
            rejectionReason: 'password',
            isRejected: true,
          });
        }
      }

      const isSomeRejected = filesToUpload.some((item) => item.isRejected);

      if (isSomeRejected) {
        setStep(2);
        setUploadingFiles(filesToUpload);
      } else {
        await uploadAllDocuments(filesToUpload);
      }
    };

    const deleteRejectDoc = (documentId: string) => {
      const filesToUpload = uploadingFiles.filter(
        (doc) => doc.uniqueId !== documentId
      );
      setUploadingFiles(filesToUpload);
      const hasRejectedFiles = filesToUpload.some(
        (item) => item.isRejected === true
      );
      if (!hasRejectedFiles) {
        uploadAllDocuments(filesToUpload);
      }
    };

    const updateSingleDocument = (file: FileToUpload) => {
      setUploadingFiles((prev) =>
        prev.map((doc) => {
          if (doc.uniqueId === file.uniqueId) {
            return file;
          }
          return doc;
        }));
    };

    const resolveRejectedDoc = async (file: FileToUpload) => {
      const formDataFile = file.body.get('document') as File;

      const [isValid, reason] = await isValidDocument(formDataFile);

      if (isValid) {
        updateSingleDocument({ ...file, isResolved: true });
        return;
      }

      if (reason === 'size') {
        updateSingleDocument({
          ...file,
          isResolved: false,
          rejectionReason: 'size',
        });
        return;
      }

      if (reason === 'password') {
        updateSingleDocument({
          ...file,
          isResolved: false,
          rejectionReason: 'password',
        });
      }
    };

    const rejectedFiles = uploadingFiles.filter(
      (item) => item.isRejected === true
    );

    return (
      <Modal.Root
        opened={ opened }
        onClose={ onClose }
        size={ step !== 4 ? 944 : 'lg' }
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header className={ headerRoot }>
            <Modal.Title>
              <Text size='3xl' fontWeight='semiBold'>
                {step !== 4 && t('documents.bulkUpload.uploadAllDocument')}
              </Text>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body bg={ theme.colors.neutral[0] } p={ 0 }>
            {step === 0 && (
              <BulkUploadZone
                applicantId={ applicantId }
                handleDocuments={ handleDocuments }
                documentsType={ documentsType }
              />
            )}
            {step === 1 && (
              <UploadLoader
                numOfDocs={ numberOfDocs }
                numOfUploadedDoc={ numUploadedDoc }
              />
            )}
            {step === 2 && (
              <RejectedDocuments
                rejectedDoc={ rejectedFiles }
                resolveRejectedDoc={ resolveRejectedDoc }
                deleteRejectDoc={ deleteRejectDoc }
                uploadDocuments={ () => uploadAllDocuments(uploadingFiles) }
              />
            )}
            {step === 3 && (
              <CategorizeDocuments
                files={ acceptedDocuments }
                documentsType={ documentsType }
                applicantId={ applicantId }
                opportunityId={ oppId! }
                numUploadedDoc={ numUploadedDoc }
                goNext={ () => onClose() }
              />
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }
);

export default BulkUploadModal;
