/* eslint-disable unicorn/no-array-callback-reference */
import { Button, theme } from '@huspy/forge';
import {
  Flex, Group, Skeleton, Stack, rem
} from '@mantine/core';
import {
  DocumentCategory, RequiredDocument, Document
} from '@modules/documents/api/types';
import { useTranslation } from 'react-i18next';
import {
  Navigate, useMatch, useNavigate
} from 'react-router-dom';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import Card from '@modules/core/components/Card';
import ApplicantInfo from '@modules/core/components/ApplicantInfo';
import StatusBadge from '@modules/core/components/StatusBadge';
import SecurityDisclaimer from '@modules/documents/components/SecurityDisclaimer';
import {
  Suspense, lazy, useRef, useState
} from 'react';
import {
  DOCUMENT_CATEGORY, DOCUMENT_STATUS, DOCUMENT_TYPES, TWO_SIDES_DOCUMENT,
} from '@modules/documents/api/const';
import { useAuthStore } from '@modules/core/store';
import { IconArrowRight } from '@tabler/icons-react';
import VaultProgressBar from '@modules/core/components/VaultProgressBar';
import HeaderCard from '@modules/core/components/HeaderCard';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import DocumentTypeCard from './components/DocumentTypeCard';

const DocumentUploadModal = lazy(() => import('./widgets/DocumentUploadModal'));
type DocumentUploadModalRef = { open(): void; close(): void } | undefined;

const accessGate: Set<DocumentCategory> = new Set(['financial', 'work', 'personal', 'additional']);

const isCompletedDocument = (doc: Document) =>
  doc.status === DOCUMENT_STATUS.valid
  || doc.status === DOCUMENT_STATUS.processing
  || doc.status === DOCUMENT_STATUS.invalid;

const isPendingDocument = (doc: Document) => doc.status === DOCUMENT_STATUS.requested;

const getDocsPerStatus = (documents: RequiredDocument[]) => {
  const completedDocuments = documents.filter((requstedDoc) => {
    if (TWO_SIDES_DOCUMENT.includes(requstedDoc.document_type_name as typeof TWO_SIDES_DOCUMENT[number])) {
      return requstedDoc.documents.every(isCompletedDocument);
    }
    return requstedDoc.documents.some(isCompletedDocument);
  });

  const pendingDocuments = documents.filter(
    (requstedDoc) => {
      if (TWO_SIDES_DOCUMENT.includes(requstedDoc.document_type_name as typeof TWO_SIDES_DOCUMENT[number])) {
        return requstedDoc.documents.some(isPendingDocument);
      }
      return requstedDoc.documents.some(isPendingDocument) && !(
        requstedDoc.documents.some(isCompletedDocument)
      );
    }

  );

  return { completedDocuments, pendingDocuments };
};

const DocumentsCategory = () => {
  const { t } = useTranslation();
  const { oppId } = useAuthStore();
  const { data: { applicants }, isLoading } = useVaultData(oppId);
  const { data: vaultProgressData } = useVaultProgress(oppId!);
  const match = useMatch(
    '/documents/:category/:applicantId'
  );

  const navigate = useNavigate();
  const ref = useRef<DocumentUploadModalRef>();
  const [selectedDocument, setSelectedDocument] = useState<number | string | undefined>();
  const applicantId = match?.params.applicantId;
  const category = match?.params.category as DocumentCategory;
  const applicant = applicants?.find((app) => app.applicant_id === applicantId!);
  const applicantCategoryProgress = vaultProgressData.documentProgress
    ?.progress_per_applicant[applicantId!]?.progress_per_category;

  if (!accessGate.has(category) || (!isLoading && !applicant) || category === 'uncategorized') {
    return <Navigate to='/documents' />;
  }

  const translations = {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    financial: { title: t('documents.financialStatements'), desc: t('documents.identity') },
    work: { title: t('documents.workDocuments'), desc: t('documents.identity') },
    personal: { title: t('documents.personalDocuments'), desc: t('documents.identity') },
    additional: { title: t('documents.additionalDocuments'), desc: t('documents.identity') },
  };

  const getDescriptionTranslation = (documentType: string) => {
    switch (documentType) {
      case DOCUMENT_TYPES.emiratesId:
      case DOCUMENT_TYPES.dni: {
        return t('documents.validDescriptionIdentity');
      }
      case DOCUMENT_TYPES.tasacion: {
        return t('documents.validDescriptionTasacion');
      }
      case DOCUMENT_TYPES.prestamos: {
        return t('documents.validDescriptionPrestamos');
      }
      default: {
        return '';
      }
    }
  };

  const documents = applicant?.required_documents[category]! ?? [];
  const currentDocType = documents.findIndex(
    (doc) => doc.document_type_id === selectedDocument || doc.document_type_name === selectedDocument
  );

  let nextDocType = documents[currentDocType + 1];
  if (documents.length === 1) {
    nextDocType = undefined;
  } else if (currentDocType + 1 >= documents.length) {
    nextDocType = documents?.[0];
  } else {
    nextDocType = documents[currentDocType! + 1];
  }

  const selectedDocType = documents[currentDocType];
  const { completedDocuments, pendingDocuments } = getDocsPerStatus(documents);

  const havePendingDocuments = pendingDocuments.length > 0;
  const invalidDescription = t('documents.invalidDescription');

  const loadingContent = (
    <>
      <ApplicantInfo.Skeleton showGenericVersion />
      <Stack gap={ theme.spacing.lg }>
        <Skeleton h={ rem(20) } w={ rem(100) } />
        <Skeleton h={ rem(76) } w='100%' radius='12px' />
        <Skeleton h={ rem(76) } w='100%' radius='12px' />
        <Skeleton h={ rem(76) } w='100%' radius='12px' />
      </Stack>
    </>
  );

  const modalFooter = (
    <Flex justify='flex-end'>
      <Button variant='tertiary' onClick={ () => ref.current?.close() }>
        {t('documents.uploadModal.cancelButton')}
      </Button>
      <Button
        ml={ theme.spacing.xl }
        disabled={ false }
        rightSection={ (
          <IconArrowRight
            stroke={ 1.5 }
          />
        ) }
        onClick={ () => {
          if (nextDocType) {
            setSelectedDocument(nextDocType?.document_type_id ?? nextDocType?.document_type_name);
          } else {
            navigate('/documents');
          }
        } }
      >
        { nextDocType?.document_type_name ?? t('documents.title') }
      </Button>
    </Flex>
  );

  return (
    <>
      <Suspense>
        <DocumentUploadModal
          applicantId={ applicant?.applicant_id! }
          ref={ ref }
          documentTypeDescription=''
          footer={ modalFooter }
          requiredDocument={ selectedDocType! }
        />
      </Suspense>
      <FloatingContainer>
        <Stack align='stretch' gap={ theme.spacing.lg } mih='100%'>
          <Group grow gap={ theme.spacing.lg } visibleFrom='md' align='flex-start'>

            {(applicantCategoryProgress?.personal?.total ?? 0) > 0 && (
              <HeaderCard
                name={ t('documents.personalDocuments') }
                data-test='personal-documents-title'
                isLoading={ isLoading }
                progress={ applicantCategoryProgress?.personal.uploaded_percentage }
                icon='sign-document.svg'
                to={ `/documents/personal/${applicantId}` }
                isActive={ category === DOCUMENT_CATEGORY.personal }
              />

            )}

            {(applicantCategoryProgress?.work?.total ?? 0) > 0 && (
              <HeaderCard
                name={ t('documents.workDocuments') }
                data-test='work-documents-title'
                isLoading={ isLoading }
                progress={ applicantCategoryProgress?.work.uploaded_percentage }
                icon='work-document.svg'
                to={ `/documents/work/${applicantId}` }
                isActive={ category === DOCUMENT_CATEGORY.work }
              />
            )}

            {(applicantCategoryProgress?.financial?.total ?? 0) > 0 && (
              <HeaderCard
                name={ t('documents.financialStatements') }
                data-test='financial-documents-title'
                isLoading={ isLoading }
                progress={ applicantCategoryProgress?.financial.uploaded_percentage }
                icon='financial-document.svg'
                to={ `/documents/financial/${applicantId}` }
                isActive={ category === DOCUMENT_CATEGORY.financial }
              />
            )}
            {(applicantCategoryProgress?.additional?.total ?? 0) > 0 && (
              <HeaderCard
                name={ t('documents.additionalDocuments') }
                data-test='additional-documents-title'
                isLoading={ isLoading }
                progress={ applicantCategoryProgress?.additional.uploaded_percentage }
                icon='additional-docs.svg'
                to={ `/documents/additional/${applicantId}` }
                isActive={ category === DOCUMENT_CATEGORY.additional }
              />
            )}

          </Group>
          <Flex gap={ theme.spacing.lg } w='100%' mih='100%' align='start'>
            <Group grow gap={ theme.spacing.lg } style={ { flexGrow: 1 } }>
              <Card
                title={ translations[category]?.title }
                description={ translations[category]?.desc }
              >
                <Stack gap={ theme.spacing['2xl'] }>
                  {isLoading ? (loadingContent) : (
                    <>
                      <ApplicantInfo
                        applicantType={ applicant?.applicant_type! }
                        name={ `${applicant?.first_name} ${applicant?.last_name}` }
                        applyResponsiveStyles={ false }
                      />
                      <>
                        {havePendingDocuments && (
                          <Stack gap={ theme.spacing.lg }>
                            <StatusBadge
                              actionsLeft={ pendingDocuments.length }
                              text={ t('documents.pending') }
                            />

                            {pendingDocuments.map(({ document_type_name, document_type_id, documents: documentFiles }) => {
                              const isInvalidDocument = documentFiles.some(
                                (item) => item.status === DOCUMENT_STATUS.invalid
                              );
                              const validDescription = getDescriptionTranslation(document_type_name);
                              const description = isInvalidDocument ? invalidDescription : validDescription;
                              const inValidReason = isInvalidDocument ? documentFiles.find(
                                (doc: Document) => doc.status === DOCUMENT_STATUS.invalid
                              )?.invalid_reason : undefined;
                              return (
                                <DocumentTypeCard
                                  description={ description }
                                  data-test={ `document-${document_type_name}` }
                                  onClick={ () => {
                                    setSelectedDocument(document_type_id ?? document_type_name);
                                    ref.current?.open();
                                  } }
                                  title={ document_type_name }
                                  key={ `${document_type_id ?? 'additional'}-${document_type_name}` }
                                  status={ isInvalidDocument ? DOCUMENT_STATUS.invalid : DOCUMENT_STATUS.requested }
                                  invalidReason={ inValidReason }
                                />
                              );
                            })}

                          </Stack>
                        )}

                        { completedDocuments.length > 0 && (
                          <Stack gap={ theme.spacing.lg }>
                            <StatusBadge
                              text={ t('documents.complete') }
                            />

                            {completedDocuments.map((doc) => {
                              const isInvalidDocument = doc.documents.some(
                                (item) => item.status === DOCUMENT_STATUS.invalid
                              );
                              const inValidReason = isInvalidDocument ? doc.documents.find(
                                (item: Document) => item.status === DOCUMENT_STATUS.invalid
                              )?.invalid_reason : undefined;
                              return (
                                <DocumentTypeCard
                                  onClick={ () => {
                                    setSelectedDocument(doc.document_type_id ?? doc.document_type_name);
                                    ref.current?.open();
                                  } }
                                  title={ doc.document_type_name }
                                  key={ `${doc.document_type_id ?? 'additional'}-${doc.document_type_name}` }
                                  status={ isInvalidDocument ? DOCUMENT_STATUS.invalid : DOCUMENT_STATUS.valid }
                                  invalidReason={ inValidReason }
                                />
                              );
                            })}
                          </Stack>
                        ) }
                      </>
                      <SecurityDisclaimer />
                    </>
                  )}
                </Stack>
              </Card>
            </Group>
            <VaultProgressBar />
          </Flex>
        </Stack>
      </FloatingContainer>
    </>
  );
};

export default DocumentsCategory;
