import { useRef, useState } from 'react';
import {
  Box, Card, FileInput, Group, Stack, TextInput
} from '@mantine/core';
import Loader from '@shared/loader';
import { Button, Text } from '@huspy/briks-web';
import { IconFileInfo, IconCheck } from '@tabler/icons-react';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import {
  FilesToUpload,
  FileToUpload,
  MAX_FILE_SIZE_MB,
} from '../DocumentUploadModal/UploadDropzone';
import { removePdfPassword } from './pdfUtils';
import {
  documentUploadModalStyles,
  footerContainer,
} from '../DocumentUploadModal/styles/index.css';

const { body } = documentUploadModalStyles;

type RejectedDocumentsProps = {
  rejectedDoc: FilesToUpload;
  deleteRejectDoc: (documentId: string) => void;
  resolveRejectedDoc: (file: FileToUpload) => void;
  uploadDocuments: () => Promise<void>;
};

type RejectedDocumentProps = {
  file: FileToUpload;
  deleteRejectDoc: (documentId: string) => void;
  resolveRejectedDoc: (file: FileToUpload) => void;
};

const RejectionCard = ({
  children,
  file,
  handleDelete,
}: {
  children: React.ReactNode;
  file: FileToUpload;
  handleDelete: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      bg='rgba(249, 250, 251, 1)'
      p='md'
      radius='md'
      w='100%'
      style={ {
        borderRadius: '8px',
        border: '1px solid rgba(229, 231, 235, 1)',
      } }
      mb='md'
    >
      <Group gap='lg' align='center' justify='space-between' w='100%'>
        <Stack gap='xs'>
          <Text
            w='200px'
            fontWeight='semiBold'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
          >
            {' '}
            {file.fileName}
            {' '}
          </Text>
          <Text
            size='sm'
            cursor='pointer'
            color='gray.9'
            onClick={ handleDelete }
          >
            {t('documents.bulkUpload.rejectedDocuments.delete')}
          </Text>
        </Stack>
        {file.isResolved ? (
          <Box
            p='xs'
            style={ {
              background: 'rgba(16, 185, 129)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            } }
          >
            <IconCheck color='white' />
          </Box>
        ) : (
          children
        )}
      </Group>
    </Card>
  );
};

const RejectionHeader = ({
  isAllResolved,
  numOfRejectDoc,
}: {
  isAllResolved: Boolean;
  numOfRejectDoc: number;
}) => {
  const { t } = useTranslation();

  if (isAllResolved) {
    return (
      <Group mb='lg'>
        <Box
          p='md'
          style={ {
            background: 'rgba(16, 185, 129)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
          } }
        >
          <IconCheck color='white' />
        </Box>
        <Stack gap='xs'>
          <Text size='2xl' fontWeight='bold'>
            {t('documents.bulkUpload.rejectedDocuments.resolvedTitle')}
          </Text>
          <Text size='md' color='gray.9'>
            {t('documents.bulkUpload.rejectedDocuments.resolvedDescription')}
          </Text>
        </Stack>
      </Group>
    );
  }
  return (
    <Group mb='lg'>
      <Box
        p='md'
        style={ {
          background: 'rgba(254, 234, 199, 1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '8px',
        } }
      >
        <IconFileInfo color='rgba(245, 158, 11, 1)' />
      </Box>
      <Stack gap='xs'>
        <Text size='2xl' fontWeight='bold'>
          {t('documents.bulkUpload.rejectedDocuments.title', { count: numOfRejectDoc })}
        </Text>
        <Text size='md' color='gray.9'>
          {t('documents.bulkUpload.rejectedDocuments.description')}
        </Text>
      </Stack>
    </Group>
  );
};

const PasswordRejectedDocument = ({
  file,
  deleteRejectDoc,
  resolveRejectedDoc,
}: RejectedDocumentProps) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formDataFile = file.body.get('document') as File;
  const { t } = useTranslation();

  const handleClick = async () => {
    setErrorMessage('');
    setLoading(true);
    try {
      const unprotectedFile = await removePdfPassword(formDataFile, password);
      file.body.set('document', new File([unprotectedFile], file.fileName));
      resolveRejectedDoc(file);
    } catch (error: any) {
      if (error?.message) {
        setErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  return (
    <RejectionCard
      file={ file }
      handleDelete={ () => deleteRejectDoc(file.uniqueId) }
    >
      <Group wrap='nowrap' align='start'>
        <TextInput
          onChange={ ({ currentTarget: { value } }) => setPassword(value) }
          value={ password }
          error={ errorMessage }
        />
        <Button w='100px' onClick={ handleClick } disabled={ loading }>
          {loading ? (
            <Loader size={ 16 } />
          ) : (
            t('documents.bulkUpload.rejectedDocuments.saveButton2')
          )}
        </Button>
      </Group>
    </RejectionCard>
  );
};

const SizeRejectedDocument = ({
  file,
  deleteRejectDoc,
  resolveRejectedDoc,
}: RejectedDocumentProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleClick = (uploadedFile: File) => {
    file.body.set('document', uploadedFile);
    const newFile = { ...file, fileName: uploadedFile.name, body: file.body };
    resolveRejectedDoc(newFile);
  };

  return (
    <RejectionCard
      file={ file }
      handleDelete={ () => deleteRejectDoc(file.uniqueId) }
    >
      <Group wrap='nowrap'>
        <FileInput
          display='none'
          ref={ ref }
          onChange={ (uploadedFile) => handleClick(uploadedFile!) }
        />
        <Button w='100px' onClick={ () => ref?.current?.click() }>
          {t('documents.bulkUpload.rejectedDocuments.replaceButton')}
        </Button>
      </Group>
    </RejectionCard>
  );
};

export const RejectedDocuments = ({
  rejectedDoc,
  deleteRejectDoc,
  resolveRejectedDoc,
  uploadDocuments,
}: RejectedDocumentsProps) => {
  const { t } = useTranslation();
  const passwordRejectedDocument = rejectedDoc.filter(
    (doc) => doc.rejectionReason === 'password'
  );
  const sizeRejectedDocument = rejectedDoc.filter(
    (doc) => doc.rejectionReason === 'size'
  );

  const isAllPasswordResolved = passwordRejectedDocument.every(
    (item) => item.isResolved
  );
  const isAllSizeResolved = sizeRejectedDocument.every(
    (item) => item.isResolved
  );
  const isAllResolved = isAllPasswordResolved && isAllSizeResolved;

  return (
    <>
      <Box className={ body }>
        <Stack gap={ theme.spacing.sm } align='start'>
          <RejectionHeader
            isAllResolved={ isAllResolved }
            numOfRejectDoc={ rejectedDoc.filter((item) => !item.isResolved).length }
          />
          {passwordRejectedDocument.length > 0 && (
            <Box mb='lg' w='100%'>
              <Text color='gray.9' mb='2!'>
                {isAllPasswordResolved
                  ? t('documents.bulkUpload.rejectedDocuments.unlocked')
                  : t('documents.bulkUpload.rejectedDocuments.needsPassword')}
              </Text>
              {passwordRejectedDocument.map((doc) => (
                <PasswordRejectedDocument
                  key={ doc.uniqueId }
                  file={ doc }
                  deleteRejectDoc={ deleteRejectDoc }
                  resolveRejectedDoc={ resolveRejectedDoc }
                />
              ))}
            </Box>
          )}
          {sizeRejectedDocument.length > 0 && (
            <Box mb='lg' w='100%'>
              <Group justify='space-between'>
                <Text color='gray.9' mb='2!'>
                  {isAllSizeResolved
                    ? t('documents.bulkUpload.rejectedDocuments.sizeLimit')
                    : t('documents.bulkUpload.rejectedDocuments.fileTooLarge')}
                </Text>
                <Text color='gray.9' mb='2!'>
                  {t('documents.bulkUpload.rejectedDocuments.maxLimit', { maxSize: MAX_FILE_SIZE_MB })}
                </Text>
              </Group>
              {sizeRejectedDocument.map((doc) => (
                <SizeRejectedDocument
                  key={ doc.uniqueId }
                  file={ doc }
                  deleteRejectDoc={ deleteRejectDoc }
                  resolveRejectedDoc={ resolveRejectedDoc }
                />
              ))}
            </Box>
          )}
        </Stack>
      </Box>
      <Group
        bg='gray.0'
        align='center'
        justify='end'
        w='100%'
        style={ { padding: '16px' } }
        className={ footerContainer }
      >
        <Button
          variant='outline'
          px='4!'
          onClick={ async () => {
            await uploadDocuments();
          } }
        >
          {t('documents.bulkUpload.rejectedDocuments.skipButton')}
        </Button>
        <Button
          px='4!'
          disabled={ !isAllResolved }
          onClick={ async () => {
            await uploadDocuments();
          } }
        >
          {t('documents.bulkUpload.rejectedDocuments.saveButton')}
        </Button>
      </Group>
    </>
  );
};
