import { LNG_PREFERENCE_KEY } from './const';

// eslint-disable-next-line no-promise-executor-return
export const sleep = (ms: number = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

export const covertToYYYYMMDDFormat = (date: string | Date) => {
  const isoDate = typeof date === 'object' ? date : new Date(date);
  const year = isoDate.getFullYear();
  const month = String(isoDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(isoDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getSessionStorageLanguagePreference = () => sessionStorage.getItem(LNG_PREFERENCE_KEY);

export const isDifferenceMoreThanFiveMinutes = (specificDateStr: string): boolean => {
  // Define the specific date
  const specificDate = new Date(specificDateStr);

  // Get the current date and time in UTC
  const now = new Date().toISOString().slice(0, -1);
  const currentDate = new Date(now);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(currentDate.getTime() - specificDate.getTime());

  // Define 5 minutes in milliseconds
  const fiveMinutesInMilliseconds = 5 * 60 * 1000;

  // Check if the difference is no more than 5 minutes
  return differenceInMilliseconds > fiveMinutesInMilliseconds;
};
