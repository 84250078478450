import http from '@shared/http';
import { API_V1_URL, API_V2_URL } from '@shared/const';
import { DirectFeatureFlagResponse, VaultOverview } from './types';

export const coreApi = {
  login: (password: string, opportunityId: string) =>
    http.post<{
      access_token: string;
      expires_in: number;
      token_type: string;
    }>(
      `${API_V1_URL}/identity/vault/auth`,
      {
        password,
        vault_id: opportunityId,
      },
      {},
      undefined,
      false
    ),
  getVaultOverview: (opportunityId: string) =>
    http.get<VaultOverview>(`${API_V2_URL}/vaults/${opportunityId}/overview`),
  getVaultFeatureFlags: () =>
    http.get<{ has_access_to_bulk_upload: boolean }>(
      `${API_V2_URL}/vaults/feature-flags`
    ),
  getFeatureFlagDirectly: (userId: string, flagKey: string) =>
    http.get<DirectFeatureFlagResponse>(
      AMPLITUDE_FEATURE_FLAGS_API_URL,
      {
        user_id: userId,
        flag_key: flagKey,
      },
      {
        Authorization: `Api-Key ${AMPLITUDE_FEATURE_FLAGS_API_KEY}`,
        Accept: 'application/json;charset=utf-8',
      }
    ),
};
