import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { documentsApi } from '@modules/documents/api';
import { Vault } from '@modules/documents/api/types';

const useDeleteUncategorizedDocuments = (
  opportunityId: string,
  applicantId: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    error, mutate, isPending, mutateAsync,
  } = useMutation({
    mutationKey: ['deleteDocument', opportunityId, applicantId],
    mutationFn: (opportunityApplicantDocumentId: string) =>
      documentsApi.deleteUncategorizedDocument(
        opportunityId,
        applicantId,
        opportunityApplicantDocumentId
      ),
    onSuccess: (_, opportunityApplicantDocumentId) => {
      queryClient.refetchQueries({ queryKey: ['vault', opportunityId] });
      queryClient.setQueryData<Vault>(['vault', opportunityId], (old) => {
        if (!old) return old;
        const newApplicants = old.applicants!.map((applicant) => {
          if (applicant.applicant_id === applicantId) {
            const newFiles = applicant.required_documents.uncategorized.filter(
              (item) => item.opportunity_applicant_document_id !== opportunityApplicantDocumentId
            );
            return {
              ...applicant,
              required_documents: {
                ...applicant.required_documents,
                uncategorized: newFiles,
              },
            };
          }
          return applicant;
        });
        return {
          ...old,
          applicants: newApplicants,
        };
      });
      toast('info', { message: t('documents.notifications.documentDeleted') });
    },
    onError: () => {
      toast('error', { message: t('documents.notifications.documentDeleteFailed') });
    },
  });

  return {
    mutate,
    error,
    isPending,
    mutateAsync,
  };
};

export default useDeleteUncategorizedDocuments;
