import {
  Box, Flex, Skeleton, rem
} from '@mantine/core';
import { theme } from '@huspy/forge';
import { useTranslation } from 'react-i18next';
import NavigatorCard from '@modules/core/components/NavigatorCard';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import StatusBadge from '@modules/core/components/StatusBadge';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import SecurityDisclaimer from '@modules/documents/components/SecurityDisclaimer';
import Card from '@modules/core/components/Card';
import { useAuthStore } from '@modules/core/store';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';

const pendingActionsLeft = (...args: boolean[]) => args.filter(Boolean).length;

const Home = () => {
  const { t } = useTranslation();
  const { oppId } = useAuthStore();

  const { isLoading: isVaultLoading } = useVaultData(oppId);
  const {
    isLoading: isKycLoading,
    isDisabled: isKycDisabled,
  } = useKYCData(oppId);
  const { data: vaultProgressData, isLoading: isOverviewLoading } = useVaultProgress(oppId!);
  const validDocuments = vaultProgressData.documentProgress.uploaded;
  const totalDocuments = vaultProgressData.documentProgress.total;

  const pendingActions = pendingActionsLeft(
    totalDocuments > validDocuments,
    (!isKycDisabled && vaultProgressData.kycProgress.total > vaultProgressData.kycProgress.count)
  );

  return (
    <FloatingContainer>
      <Card>
        <Flex direction='column' justify='space-between'>
          <Box>
            {isVaultLoading && isKycLoading ? (<Skeleton height={ rem(25) } width={ rem(120) } />) : (
              <StatusBadge
                text={ (validDocuments === 0 && t('home.documentsOverview.pending'))
                  || (validDocuments < totalDocuments && t('home.documentsOverview.inProgress'))
                  || t('home.documentsOverview.complete') }
                actionsLeft={ pendingActions }
              />
            )}
            <Flex direction='column' gap={ theme.spacing.xl } mt={ theme.spacing.lg }>
              {isOverviewLoading ? (<Skeleton height={ rem(122) } radius='12px' />) : (
                <NavigatorCard
                  title={ t('home.documentsOverview.documentsCard.title') }
                  description={ t('home.documentsOverview.documentsCard.description') }
                  buttonText={ t('home.documentsOverview.documentsCard.buttonText') }
                  progress={ vaultProgressData.documentProgress.uploaded_percentage === 0 ? undefined
                    : vaultProgressData.documentProgress.uploaded_percentage }
                  isLoading={ isOverviewLoading }
                  to='/documents'
                  data-test='documents-title'
                  progressBarFullWidth={ false }
                  styles={ { root: { width: '100%' } } }
                />
              )}

              {isOverviewLoading ? (<Skeleton height={ rem(122) } radius='12px' />) : (
                <NavigatorCard
                  iconName={ isKycDisabled ? 'sign-document-grey.svg' : 'sign-document.svg' }
                  title={ t('home.kycCard.title') }
                  description={ t('home.kycCard.description') }
                  note={ t('home.kycCard.note') }
                  buttonText={ isKycDisabled ? 'Not requested' : t('home.kycCard.buttonText') }
                  progress={ vaultProgressData.kycProgress.percentage === 0 ? undefined
                    : vaultProgressData.kycProgress.percentage }
                  isLoading={ isOverviewLoading }
                  to='/kyc'
                  disabled={ isKycDisabled }
                  progressBarFullWidth={ false }
                  data-test='getting-to-know-you-title'
                  trackEvent={ () => {
                    trackAmplitudeEvent('KYC Clicked');
                  } }
                  styles={ { root: { width: '100%' } } }
                />
              )}
            </Flex>
          </Box>
          <SecurityDisclaimer />
        </Flex>

      </Card>
    </FloatingContainer>
  );
};

export default Home;
