import {
  Text, Tooltip, theme
} from '@huspy/forge';
import { iconsPath } from '@shared/css.const';
import {
  Flex, Group, Image, Stack,
} from '@mantine/core';
import {
  IconArrowRight, IconCircleCheckFilled, IconExclamationCircle
} from '@tabler/icons-react';
import { DOCUMENT_STATUS } from '@modules/documents/api/const';
import { DocumentStatus } from '@modules/documents/api/types';
import { documentTypeCardStyles } from './styles/index.css';

type Props = {
  title: string;
  onClick?(): void;
  status: DocumentStatus;
  description?: string;
  invalidReason?: string;
};

const { wrapper, stack, icon } = documentTypeCardStyles;

const DocumentTypeCard = ({
  title, description, status, onClick, invalidReason, ...rest
}: Props) => (
  <>
    {(() => {
      switch (status) {
        case DOCUMENT_STATUS.valid: {
          return (
            <Flex
              className={ documentTypeCardStyles.root({ status }) }
              w='100%'
              onClick={ () => onClick?.() }
              { ...rest }
            >
              <Group className={ wrapper } justify='space-between' w='100%' align='flex-start'>

                <Group>
                  <Flex>
                    <IconCircleCheckFilled
                      size={ 16 }
                      style={ { color: theme.colors.green[4], alignSelf: 'center' } }
                    />
                  </Flex>
                  <Flex>
                    <Text fw={ 600 }>
                      { title }
                    </Text>
                  </Flex>
                </Group>

                <Flex>
                  <IconArrowRight color={ theme.colors.neutral[5] } size={ 18 } cursor='pointer' />
                </Flex>
              </Group>
            </Flex>
          );
        }
        case DOCUMENT_STATUS.invalid: {
          return (
            <Flex
              className={ documentTypeCardStyles.root({ status }) }
              w='100%'
              onClick={ () => onClick?.() }
              { ...rest }
            >
              <Group className={ wrapper } justify='space-between' w='100%' align='flex-start'>

                <Group align='flex-center'>
                  <Flex>
                    <Tooltip label={ invalidReason }>
                      <IconExclamationCircle
                        size={ 20 }
                        style={ { color: theme.colors.orange[4], alignSelf: 'center' } }
                      />
                    </Tooltip>
                  </Flex>
                  <Stack gap={ theme.spacing.xs } className={ stack }>
                    <Text fw={ 600 }>
                      { title }
                    </Text>
                    <Text size='sm'>
                      { description }
                    </Text>
                  </Stack>
                </Group>

                <Flex>
                  <IconArrowRight color={ theme.colors.neutral[5] } size={ 18 } cursor='pointer' />
                </Flex>
              </Group>
            </Flex>
          );
        }
        default: {
          return (
            <Flex
              className={ documentTypeCardStyles.root({ status }) }
              w='100%'
              onClick={ () => onClick?.() }
              { ...rest }
            >
              <Group className={ wrapper } justify='space-between' w='100%' align='flex-start'>

                <Group align='flex-start'>
                  <Flex>
                    <Image
                      src={ `${iconsPath}/sign-document-grey.svg` }
                      className={ icon }
                    />
                  </Flex>
                  <Stack gap={ theme.spacing.xs } className={ stack }>
                    <Text fw={ 600 }>
                      { title }
                    </Text>
                    <Text size='sm'>
                      { description }
                    </Text>
                  </Stack>

                </Group>

                <Flex>
                  <IconArrowRight color={ theme.colors.neutral[5] } size={ 18 } cursor='pointer' />
                </Flex>
              </Group>
            </Flex>

          );
        }
      }
    })()}
  </>
);

export default DocumentTypeCard;
