import {
  Box, Flex, Image, Stack, rem
} from '@mantine/core';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import { useTranslation } from 'react-i18next';
import { Text, theme } from '@huspy/forge';
import { useAuthStore } from '@modules/core/store';
import FloatingContainer from '@modules/core/components/FloatingContainer';
import VaultProgressBar from '@modules/core/components/VaultProgressBar';
import Card from '@modules/core/components/Card';
import { imagesPath } from '@shared/css.const';
import SecurityDisclaimer from '@modules/documents/components/SecurityDisclaimer';
import AppSkeleton from '@modules/core/components/Skeleton';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import ApplicantProgress from './components/ApplicantProgress';

const Documents = () => {
  const { t } = useTranslation();
  const { oppId } = useAuthStore();
  const { data, isLoading } = useVaultData(oppId);
  const { data: vaultProgressData } = useVaultProgress(oppId!);
  const { applicants } = data;

  return (
    <FloatingContainer display='flex' style={ { gap: theme.spacing.lg } }>
      <Card>
        <Flex hiddenFrom='md' justify='space-between'>
          <Box>
            <Text size='lg' fw={ 600 }>
              {t('documents.title')}
            </Text>
            <Text
              mt={ theme.spacing.sm }
              size='sm'
              styles={ { root: { lineHeight: '130%', maxWidth: rem(238) } } }
            >
              {t('documents.subtitle')}
            </Text>
          </Box>
          <Flex>
            <Image
              src={ `${imagesPath}/documents-page.svg` }
              mah={ rem(80) }
              maw={ rem(80) }
              styles={ { root: { alignSelf: 'flex-end' } } }
            />
          </Flex>
        </Flex>
        {isLoading ? (
          <Box mt={ { base: theme.spacing['2xl'], md: 0 } }>
            <AppSkeleton.ApplicantDocuments />
          </Box>
        ) : (
          <>
            <Stack
              gap={ theme.spacing['2xl'] }
              mt={ { base: theme.spacing['2xl'], md: 0 } }
            >
              {applicants?.map((applicant) => (
                <ApplicantProgress
                  key={ applicant.applicant_id }
                  applicantId={ applicant.applicant_id }
                  applicantName={ `${applicant.first_name} ${applicant.last_name}` }
                  stats={ vaultProgressData.documentProgress.progress_per_applicant[
                    applicant.applicant_id
                  ]?.progress_per_category }
                  applicantType={ applicant.applicant_type }
                  documents={ applicant.required_documents }
                />
              ))}
            </Stack>
            <SecurityDisclaimer />
          </>
        )}
      </Card>
      <VaultProgressBar />
    </FloatingContainer>
  );
};

export default Documents;
