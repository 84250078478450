import { Text, theme } from '@huspy/forge';
import {
  Box, Flex, Image, Skeleton, rem
} from '@mantine/core';
import { iconsPath } from '@shared/css.const';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import CardProgressBar from '@modules/core/components/CardProgressBar';
import { categoryTabStyles } from './styles/index.css';

type Props = {
  icon: string;
  name: string;
  progress?: number;
  to: string;
  isLoading?: boolean;
  isActive?: boolean;
  hideProgresBar?: boolean;
};

const { root, wrapper, activeRoot } = categoryTabStyles;

const HeaderCard = ({
  icon,
  name,
  progress = 0,
  isActive,
  isLoading,
  hideProgresBar = false,
  to,
}: Props) => {
  const navigate = useNavigate();
  return (
    <Box className={ clsx(root, isActive ? activeRoot : '') } onClick={ () => navigate(to) }>
      <Flex className={ wrapper }>
        <Flex align='center'>
          <Image src={ `${iconsPath}/${icon}` } w={ rem(20) } h={ rem(20) } />
        </Flex>
        <Box pl={ theme.spacing.lg }>
          <Text fw={ 600 }>
            { name }
          </Text>
          {!hideProgresBar && (isLoading ? <Skeleton h={ 8 } mt={ theme.spacing.sm } /> : (
            <CardProgressBar
              h={ 8 }
              placeholderValue={ 6 }
              mt={ theme.spacing.sm }
              styles={ { root: { width: '100%' } } }
              value={ progress }
            />
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default HeaderCard;
