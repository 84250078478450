import { useAuthStore } from '@modules/core/store';
import { datadogLogs } from '@datadog/browser-logs';
import { Fetcher, toast } from '@huspy/forge/shared';
import { VAULT_TOKEN_KEY } from '../const';
import { VaultException } from '../exceptions';

const ContentTypeApplicationJson = 'application/json';
export class BaseFetcher extends Fetcher {
  // eslint-disable-next-line class-methods-use-this
  public async request<T extends {}>(
    url: RequestInfo | URL,
    method: string,
    body: Record<string, unknown> | null,
    headers: HeadersInit,
    signal: AbortController['signal'],
    sendAuthorizationHeaders: boolean = true
  ): Promise<T> {
    let defaultHeaders: Record<string, any> = {};
    if (sendAuthorizationHeaders) {
      const token = sessionStorage.getItem(VAULT_TOKEN_KEY);
      defaultHeaders = {
        Authorization: `Bearer ${token}`,
        'x-source-product': 'vault',
      };
    }

    if (!(body instanceof FormData)) {
      defaultHeaders['Content-Type'] = ContentTypeApplicationJson;
    }

    const config: RequestInit = {
      method,
      ...(signal || undefined),
      headers: {
        ...defaultHeaders,
        ...headers,
      },
    };

    if (body instanceof FormData) {
      config.body = body;
    } else if (body) {
      config.body = JSON.stringify(body);
    } else {
      config.body = null;
    }

    datadogLogs.logger.info(`Calling: ${method} ${url.toString()}`, {
      payload: body,
      request: { method, url: url.toString() },
    });
    const response = await fetch(url, config);
    try {
      const authStore = useAuthStore.getState();
      if (response.status === 401 && authStore.token) {
        authStore.logOut();
        toast('warning', { message: 'Your session ended, please log in again!' });
      }

      if (response.status === 503) {
        window.location.replace('/maintenance');
      }

      if (!response.ok) {
        const err = await response.json();
        throw new VaultException(
          err.message ?? 'Vault API error',
          'VaultError',
          response.status,
          JSON.stringify(err),
          err
        );
      }

      const responseData: T = await response.json();
      datadogLogs.logger.info(`Success: ${method} ${url.toString()}`, {
        payload: responseData,
        request: { method, url: url.toString() },
      });
      return responseData;
    } catch (error: any) {
      datadogLogs.logger.error(`${method} ${url.toString()}`, { error, request: { method, url: url.toString() } });
      throw error;
    }
  }
}
