import { RequestCategorizeDocuments, documentsApi } from '@modules/documents/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { Vault } from '@modules/documents/api/types';

export type RequestDownloadDocumentType = {
  opportunityApplicantDocumentExternalID: string;
};

const useCategorizeDocuments = (opportunityExternalID: string, opportunityApplicantExternalID: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: ['useCategorizeDocuments', opportunityExternalID, opportunityApplicantExternalID],
    mutationFn: (request: RequestCategorizeDocuments) =>
      documentsApi.categorizeDocuments(
        opportunityExternalID,
        opportunityApplicantExternalID,
        request
      ),
    onSuccess: (_, request) => {
      trackAmplitudeEvent('DocumentsCategorized', { no_of_documents_categorized: request.documents.length });
      const categorizedDocuments = new Set(request.documents.map((item) => item.applicant_document_external_id));
      queryClient.refetchQueries({ queryKey: ['vault', opportunityExternalID] });
      queryClient.setQueryData<Vault>(['vault', opportunityExternalID], (old) => {
        if (!old) return old;
        const newApplicants = old.applicants!.map((applicant) => {
          if (applicant.applicant_id === opportunityApplicantExternalID) {
            const newFiles = applicant.required_documents.uncategorized.filter(
              (item) => !categorizedDocuments.has(item.opportunity_applicant_document_id)
            );
            return {
              ...applicant,
              required_documents: {
                ...applicant.required_documents,
                uncategorized: newFiles,
              },
            };
          }
          return applicant;
        });
        return {
          ...old,
          applicants: newApplicants,
        };
      });
    },
    onError: (data) => {
      toast('error', { message: data.message || t('documents.notifications.documentUploadFailed') });
    },
  });

  return {
    mutate, mutateAsync, error, isPending,
  };
};

export default useCategorizeDocuments;
