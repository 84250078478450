import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';

const useGetVaultFeatureFlags = () => {
  const {
    data, isLoading, error, refetch, isRefetching,
  } = useQuery({
    queryKey: ['VaultFeatureFlags'],
    queryFn: () => coreApi.getVaultFeatureFlags(),
    staleTime: Number.POSITIVE_INFINITY,
    retry: 0,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export default useGetVaultFeatureFlags;
