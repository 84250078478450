import { documentsApi } from '@modules/documents/api';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';

export type RequestDownloadDocumentType = {
  opportunityApplicantDocumentExternalID: string;
};

const useDownloadDocument = (opportunityExternalID: string, opportunityApplicantExternalID: string) => {
  const { t } = useTranslation();
  const {
    error, mutate, mutateAsync, isPending,
  } = useMutation({
    mutationKey: ['useDownloadDocument', opportunityExternalID, opportunityApplicantExternalID],
    mutationFn: (request: RequestDownloadDocumentType) =>
      documentsApi.downloadDocument(
        opportunityExternalID,
        opportunityApplicantExternalID,
        request.opportunityApplicantDocumentExternalID
      ),
    onError: (data) => {
      toast('error', { message: data.message || t('documents.notifications.documentUploadFailed') });
    },
  });

  return {
    mutate, mutateAsync, error, isPending,
  };
};

export default useDownloadDocument;
