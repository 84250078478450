import { coreApi } from '@modules/core/api';
import { VaultKYCProgress, VaultOverview } from '@modules/core/api/types';
import { useQuery } from '@tanstack/react-query';

const getKYCProgress = (data: VaultKYCProgress) => {
  const disabled = Object.keys(data.progress_per_applicant).length === 0;
  const total = disabled ? 0 : data.total;
  const count = disabled ? 0 : data.count;
  const percentage = disabled ? 0 : data.percentage;
  return {
    disabled, total, count, percentage, progress_per_applicant: data.progress_per_applicant,
  };
};

const fetchProgress = ({
  business_identifier: businessIdentifier,
  progress: {
    kyc_progress: kycProgress,
    document_progress: documentProgress,
  },
} : VaultOverview) => ({
  businessIdentifier,
  kycProgress: getKYCProgress(kycProgress),
  documentProgress,
});

const useVaultProgress = (opportunityId: string | undefined) => {
  const {
    data, isLoading, error, refetch, isFetched,
  } = useQuery({
    queryKey: ['vaultProgress', opportunityId],
    queryFn: () => coreApi.getVaultOverview(opportunityId!),
    enabled: !!opportunityId,
    staleTime: 1_800_000,
    refetchInterval: 10000,
    select: (queryData) => fetchProgress(queryData),
  });

  return {
    data: data ?? {
      businessIdentifier: '' as VaultOverview['business_identifier'],
      kycProgress: {
        disabled: false, total: 0, count: 0, percentage: 0, progress_per_applicant: {},
      } as VaultOverview['progress']['kyc_progress'],
      documentProgress: {
        total: 0, valid: 0, percentage: 0, progress_per_applicant: {},
      } as VaultOverview['progress']['document_progress'],
    },
    isLoading,
    error,
    refetch,
    isFetched,
  };
};

export default useVaultProgress;
