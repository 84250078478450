import http from '@shared/http';
import { API_V2_URL } from '@shared/const';
import { Vault } from './types';

export type PresignedUrlResponseType = {
  url: string;
  fields: Record<string, unknown>;
}[];

export type SingleCategorizeDocument = {
  applicant_document_external_id: string;
  document_type_id?: number;
  document_type_name?: string;
};

export type RequestCategorizeDocuments = {
  documents: SingleCategorizeDocument[];
};

export const documentsApi = {
  getVault: (opportunityId: string) => http.get<Vault>(`${API_V2_URL}/vaults/${opportunityId}`),
  downloadDocument: (opportunityId: string, applicantId: string, documentId: string) =>
    http.get<{ url: string }>(
      `${API_V2_URL}/vaults/${opportunityId}/applicants/${applicantId}/documents/${documentId}/download`
    ),
  categorizeDocuments: (opportunityId: string, applicantId: string, body: RequestCategorizeDocuments) =>
    http.post<any>(`${API_V2_URL}/vaults/${opportunityId}/applicants/${applicantId}/documents/categorize`, body),
  uploadDocument: (opportunityId: string, opportunityApplicantDocumentId: string, body: FormData) =>
    http.post<any>(`${API_V2_URL}/vaults/${opportunityId}/documents/${opportunityApplicantDocumentId}`, body),
  deleteDocument: (opportunityId: string, opportunityApplicantDocumentId: string) =>
    http.delete(`${API_V2_URL}/vaults/${opportunityId}/documents/${opportunityApplicantDocumentId}`),
  deleteUncategorizedDocument: (
    opportunityId: string,
    opportunityApplicantId: string,
    opportunityApplicantDocumentId: string
  ) =>
    // eslint-disable-next-line max-len
    http.delete(`${API_V2_URL}/vaults/${opportunityId}/applicants/${opportunityApplicantId}/uncategorized-documents/${opportunityApplicantDocumentId}`),
  getBulkUploadPresignedUrls: (
    opportunityId: string,
    opportunityApplicantExternalID: string,
    filesNames: string[]
  ) =>
    http.post<{ response: PresignedUrlResponseType }>(
      `${API_V2_URL}/vaults/${opportunityId}/applicants/${opportunityApplicantExternalID}/documents/presigned-url/bulk`,
      { file_names: filesNames }
    ),
  getPresignedUrl: (
    opportunityExternalID: string,
    opportunityApplicantExternalID: string,
    opportunityApplicantDocumentExternalID: string,
    fileName: string
  ) => http.get<any>(
    // eslint-disable-next-line max-len
    `${API_V2_URL}/vaults/${opportunityExternalID}/applicants/${opportunityApplicantExternalID}/documents/${opportunityApplicantDocumentExternalID}/presigned-url?file_name=${fileName}`
  ),
  uploadDocumentUsingPresignedUrl: (presignedUrl: string, fields: any, body: FormData) => {
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append('file', body.get('document')!);
    return fetch(presignedUrl, {
      method: 'POST',
      body: formData,
    });
  },
};
