import { ApplicantType, RequiredDocuments } from '@modules/documents/api/types';
import ApplicantInfo from '@modules/core/components/ApplicantInfo';
import NavigatorCard from '@modules/core/components/NavigatorCard';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_STATUS } from '@modules/documents/api/const';
import { ApplicantProgressDocs } from '@modules/core/api/types';
import { useRef, useState } from 'react';
import BulkUploadModal from '@modules/documents/pages/Category/widgets/BulkUploadModal';
import { FilesToUpload } from '@modules/documents/pages/Category/widgets/DocumentUploadModal/UploadDropzone';
import { IconFileX } from '@tabler/icons-react';
import {
  Text, Flex, Button
} from '@huspy/briks-web';
import useGetVaultFeatureFlags from '@modules/core/hooks/queries/useGetVaultFeatureFlags';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { applicantProgressStyles } from './styles/index.css';

type BulkUploadModalRef = { open(): void; close(): void } | undefined;

const { categoriesWrapper, wrapper } = applicantProgressStyles;

type Props = {
  applicantId?: string;
  applicantName: string;
  stats?: ApplicantProgressDocs['progress_per_category'];
  applicantType: ApplicantType;
  documents: RequiredDocuments;
};

const ApplicantProgress = ({
  stats,
  applicantName = '',
  applicantType,
  applicantId,
  documents,
}: Props) => {
  const { t } = useTranslation();
  const {
    additional, financial, personal, work,
  } = { ...stats };
  const ref = useRef<BulkUploadModalRef>();
  const [uploadingFiles, setUploadingFiles] = useState<FilesToUpload>([]);
  const { data: featureFlags } = useGetVaultFeatureFlags();
  const unCategorizedDocument = documents?.uncategorized;
  const [step, setStep] = useState(0);
  const rejectedFiles = uploadingFiles.filter(
    (item) => item.isRejected === true
  );
  const isAllResolved = rejectedFiles.every((item) => item.isResolved);

  const hasAccessToBulkUpload = featureFlags?.has_access_to_bulk_upload;

  return (
    <Flex className={ wrapper }>
      <Flex justify='space-between'>
        <ApplicantInfo name={ applicantName } applicantType={ applicantType } />
        {hasAccessToBulkUpload
        && (
          <>
            <Button
              onClick={ () => ref.current?.open() }
              variant='solid'
              bg='neutral.900'
              px='4!'
              data-test='bulk-upload-btn'
            >
              {t('documents.bulkUpload.button')}
            </Button>
            <BulkUploadModal
              ref={ ref }
              applicantId={ applicantId! }
              documents={ documents }
              step={ step }
              setStep={ setStep }
              uploadingFiles={ uploadingFiles }
              setUploadingFiles={ setUploadingFiles }
              acceptedDocuments={ unCategorizedDocument }
            />
          </>
        )}
      </Flex>
      <Flex className={ categoriesWrapper }>
        {personal && (personal?.total ?? 0) > 0 && (
          <NavigatorCard
            title={ t('documents.personalDocuments') }
            to={ `/documents/personal/${applicantId}` }
            data-test='personal-documents-title'
            iconName='sign-document.svg'
            // eslint-disable-next-line sonarjs/no-duplicate-string
            progressBarText={ t('documents.documentsProgress', {
              valid: personal.uploaded,
              total: personal.total,
            }) }
            // eslint-disable-next-line no-unsafe-optional-chaining
            progress={ personal.uploaded_percentage }
            status={ personal.invalid && personal.invalid > 0
              ? DOCUMENT_STATUS.invalid
              : DOCUMENT_STATUS.valid }
          />
        )}
        {work && (work?.total ?? 0) > 0 && (
          <NavigatorCard
            title={ t('documents.workDocuments') }
            to={ `/documents/work/${applicantId}` }
            data-test='work-documents-title'
            iconName='work-document.svg'
            progressBarText={ t('documents.documentsProgress', {
              valid: work?.uploaded,
              total: work?.total,
            }) }
            // eslint-disable-next-line no-unsafe-optional-chaining
            progress={ work.uploaded_percentage }
            status={ work.invalid && work.invalid > 0
              ? DOCUMENT_STATUS.invalid
              : DOCUMENT_STATUS.valid }
          />
        )}
        {financial && (financial?.total ?? 0) > 0 && (
          <NavigatorCard
            title={ t('documents.financialStatements') }
            to={ `/documents/financial/${applicantId}` }
            data-test='financial-documents-title'
            iconName='financial-document.svg'
            progressBarText={ t('documents.documentsProgress', {
              valid: financial?.uploaded,
              total: financial?.total,
            }) }
            // eslint-disable-next-line no-unsafe-optional-chaining
            progress={ financial.uploaded_percentage }
            status={ financial.invalid && financial.invalid > 0
              ? DOCUMENT_STATUS.invalid
              : DOCUMENT_STATUS.valid }
          />
        )}
        {additional && (additional?.total ?? 0) > 0 && (
          <NavigatorCard
            title={ t('documents.additionalDocuments') }
            to={ `/documents/additional/${applicantId}` }
            data-test='additional-documents-title'
            iconName='additional-docs.svg'
            progressBarText={ t('documents.documentsProgress', {
              valid: additional?.uploaded,
              total: additional?.total,
            }) }
            progress={ additional.uploaded_percentage }
            status={ additional.invalid && additional.invalid > 0
              ? DOCUMENT_STATUS.invalid
              : DOCUMENT_STATUS.valid }
          />
        )}
      </Flex>
      {hasAccessToBulkUpload && unCategorizedDocument.some((document) => document.document_url) && (
        <Flex
          mb='lg'
          bg='warning.100'
          p='4!'
          borderRadius='lg'
          justifyContent='space-between'
          alignItems='center'
        >
          <Flex alignItems='center'>
            <IconFileX color='#92610E' size='40' />
            <Flex ml='2!' w='70%' gap='xs' flexDirection='column'>
              <Text size='lg' color='warning.800' fontWeight='semiBold'>
                {t('documents.bulkUpload.cautionCard.title')}
              </Text>
              <Text size='md' color='warning.800'>
                {t('documents.bulkUpload.cautionCard.description')}
              </Text>
            </Flex>
          </Flex>
          <Button
            variant='solid'
            bg='warning.600'
            w='15%'
            onClick={ () => {
              ref.current?.open();
              trackAmplitudeEvent('UncategorizedFilesModalOpened');
              if (isAllResolved) {
                setStep(3);
              } else {
                setStep(2);
              }
            } }
          >
            {t('documents.bulkUpload.cautionCard.button')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default ApplicantProgress;
