import {
  Box, Card, Flex, Stack
} from '@mantine/core';
import { Text } from '@huspy/briks-web';
import SecurityDisclaimer from '@modules/documents/components/SecurityDisclaimer';
import { RequiredDocument } from '@modules/documents/api/types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { theme } from '@huspy/forge';
import { useState } from 'react';
import UploadDropzone, { FilesToUpload } from '../DocumentUploadModal/UploadDropzone';
import {
  dropzoneRootBulkStyles,
  documentUploadModalStyles,
} from '../DocumentUploadModal/styles/index.css';
import { ValidateUploadLoader } from './UploadLoader';

const { body } = documentUploadModalStyles;

type BulkUploadZoneTypes = {
  applicantId: string;
  handleDocuments: (files: FilesToUpload) => Promise<void>;
  documentsType: RequiredDocument[];
};
export const BulkUploadZone = ({
  applicantId,
  handleDocuments,
  documentsType,
}: BulkUploadZoneTypes) => {
  const { t } = useTranslation();
  const maxAllowedFiles = documentsType.reduce(
    (acc, item) => acc + item.max_files,
    0
  );
  const [isValidating, setIsValidating] = useState(false);

  if (isValidating) {
    return <ValidateUploadLoader />;
  }

  return (
    <Box className={ body }>
      <Stack gap={ theme.spacing.xl }>
        <UploadDropzone
          aplicantId={ applicantId }
          handleUpload={ async (files: any) => {
            setIsValidating(true);
            await handleDocuments(files);
            setIsValidating(false);
          } }
          className={ clsx(dropzoneRootBulkStyles) }
          maxFiles={ maxAllowedFiles }
          checkSize={ false }
        />
        <Box>
          <Text size='lg' mb='4!' color='gray.9' fontWeight='semiBold'>
            {t('documents.bulkUpload.requiredDocuments')}
          </Text>
          <Flex
            direction='row'
            wrap='wrap'
            gap='md'
            justify={ {
              md: 'stretch',
              lg: 'stretch',
              base: 'space-around',
            } }
          >
            {documentsType.filter((item) => item.document_type_name).map((docType) => (
              <Card
                key={ docType.document_type_id }
                bg='gray.0'
                p='md'
                radius='md'
                withBorder
                miw='215px'
                style={ { borderRadius: '8px' } }
              >
                <Text size='lg' mb='sm' w='180px'>
                  {docType.document_type_name}
                </Text>
                <Text color='gray.9' size='md'>
                  {t('documents.bulkUpload.maxFilePerCategory', { maxFiles: docType.max_files })}
                </Text>
              </Card>
            ))}
            <Box w='100%'>
              <SecurityDisclaimer />
            </Box>
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
};
