import {
  Box, Card, Group, Stack
} from '@mantine/core';
import { Button, Text } from '@huspy/briks-web';
import { IconFiles } from '@tabler/icons-react';
import { Select, theme } from '@huspy/forge';
import { Document, RequiredDocument } from '@modules/documents/api/types';
import { useState } from 'react';
import Loader from '@shared/loader';
import useDownloadDocument from '@modules/documents/hooks/mutations/useDownloadDocument';
import useCategorizeDocuments from '@modules/documents/hooks/mutations/useCategorizeDocuments';
import { SingleCategorizeDocument } from '@modules/documents/api';
import { useTranslation } from 'react-i18next';
import useDeleteUncategorizedDocuments from '@modules/documents/hooks/mutations/useDeleteUncategorizedDocuments';
import { isDifferenceMoreThanFiveMinutes } from '@shared/utils';
import {
  documentUploadModalStyles,
  footerContainer,
} from '../DocumentUploadModal/styles/index.css';
import { OrganizeUploadLoader } from './UploadLoader';

const { categorizeBulkUploadBody } = documentUploadModalStyles;

type CategorizeDocumentsProps = {
  files: Document[];
  documentsType: RequiredDocument[];
  applicantId: string;
  opportunityId: string;
  numUploadedDoc: number;
  goNext: () => void;
};

type DocumentTypes = {
  name: string;
  maxFiles: number;
  count: number;
  externalIds: string[];
  id: number;
  isDisable: boolean;
}[];

type CategorizeSingleDocumentProps = {
  file: Document;
  documentsType: RequiredDocument[];
  opportunityId: string;
  applicantId: string;
  selectedDocumentType: DocumentTypes;
  closeModalOnDelete: () => void;
  updateSelectedType: (
    currentId: number | string,
    previousId: number | string,
    externalId: string,
  ) => void;
};

const downloadFile = (url: string) => {
  window?.open(url, '_blank')?.focus();
};

const shouldUpdateSelectType = (
  idToUpdate: number | string,
  id: number,
  name: string
) => {
  if (typeof idToUpdate === 'number' && id === idToUpdate) {
    return true;
  }
  return typeof idToUpdate === 'string' && name === idToUpdate;
};

const DeleteCard = ({
  file,
  close,
  opportunityId,
  applicantId,
  closeModalOnDelete,
}: {
  opportunityId: string;
  applicantId: string;
  file: Document;
  close: () => void;
  closeModalOnDelete: () => void;
}) => {
  const { mutateAsync: deleteDocument, isPending } = useDeleteUncategorizedDocuments(opportunityId, applicantId);
  const { t } = useTranslation();

  return (
    <>
      <Stack gap='xs'>
        <Text fontWeight='semibold'>
          {t('documents.bulkUpload.categorizeDocuments.deletionConfirmation')}
        </Text>
        <Text
          w='200px'
          fontWeight='semiBold'
          whiteSpace='nowrap'
          textOverflow='ellipsis'
          overflow='hidden'
          size='sm'
          color='gray.9'
        >
          {file.document_name}
        </Text>
      </Stack>
      <Group>
        <Button
          px='4!'
          disabled={ isPending }
          onClick={ async () => {
            await deleteDocument(file.opportunity_applicant_document_id);
            closeModalOnDelete();
          } }
        >
          {isPending ? (
            <Loader color='black' size={ 20 } />
          ) : (
            t('documents.bulkUpload.categorizeDocuments.yesButton')
          )}
        </Button>
        <Button px='4!' variant='outline' onClick={ close }>
          {t('documents.bulkUpload.categorizeDocuments.noButton')}
        </Button>
      </Group>
    </>
  );
};

const CategorizeSingleDocument = ({
  file,
  opportunityId,
  applicantId,
  selectedDocumentType,
  updateSelectedType,
  closeModalOnDelete,
}: CategorizeSingleDocumentProps) => {
  const [type, setType] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const { mutateAsync: downloadDocument, isPending: isPendingDownload } = useDownloadDocument(opportunityId, applicantId);
  const { t } = useTranslation();

  return (
    <Card
      bg='rgba(249, 250, 251, 1)'
      p='md'
      radius='md'
      w='100%'
      style={ {
        borderRadius: '8px',
        border: '1px solid rgba(229, 231, 235, 1)',
      } }
      mb='md'
    >
      <Group gap='lg' align='center' justify='space-between' w='100%'>
        {showDelete ? (
          <DeleteCard
            opportunityId={ opportunityId }
            applicantId={ applicantId }
            file={ file }
            close={ () => setShowDelete(false) }
            closeModalOnDelete={ closeModalOnDelete }
          />
        ) : (
          <>
            <Stack gap='xs'>
              <Text
                w='200px'
                fontWeight='semiBold'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
              >
                {' '}
                {file.document_name}
                {' '}
              </Text>
              <Group>
                <Text
                  size='sm'
                  cursor='pointer'
                  color='gray.9'
                  onClick={ async () => {
                    const response = await downloadDocument({ opportunityApplicantDocumentExternalID: file.id });
                    downloadFile(response.url);
                  } }
                >
                  {isPendingDownload ? (
                    <Loader color='black' size={ 15 } />
                  ) : (
                    t('documents.bulkUpload.categorizeDocuments.preview')
                  )}
                </Text>
                <Text
                  size='xs'
                  cursor='pointer'
                  color='gray.9'
                  fontWeight='bold'
                >
                  |
                </Text>
                <Text
                  size='sm'
                  cursor='pointer'
                  color='gray.9'
                  onClick={ () => setShowDelete(true) }
                >
                  {t('documents.bulkUpload.categorizeDocuments.delete')}
                </Text>
              </Group>
            </Stack>
            <Select
              w={ { md: '50%', lg: '30%' } }
              allowSearch
              dropdownScroll
              data-test='select-document-type'
              data={ selectedDocumentType.map((item) => ({
                label: item.name,
                value: item.id ? String(item.id) : item.name,
                disabled: item.isDisable,
              })) }
              onChange={ (value) => {
                updateSelectedType(
                  Number(value) || value,
                  Number(type) || type,
                  file.opportunity_applicant_document_id
                );
                setType(value);
              } }
              value={ type }
            />
          </>
        )}
      </Group>
    </Card>
  );
};

export const CategorizeDocuments = ({
  files,
  documentsType,
  applicantId,
  opportunityId,
  numUploadedDoc,
  goNext,
}: CategorizeDocumentsProps) => {
  const savedFiles = files.filter((file) => file.document_url);
  const someFilesHanging = files.filter((item) => !item.document_url).some(
    (file) => !isDifferenceMoreThanFiveMinutes(file.created_at!)
  );

  const isLoading = (savedFiles.length === 0 && numUploadedDoc !== 0)
    || savedFiles.length === 0
    || someFilesHanging;

  const { t } = useTranslation();
  const initialDocumentTypes = documentsType
    .filter((item) => item.document_type_name)
    .map((item) => {
      const numberOfDocuments = item.documents.filter(
        (document) => document.id
      ).length;
      const isDisable = numberOfDocuments >= item.max_files;
      return {
        name: item.document_type_name,
        maxFiles: item.max_files,
        count: numberOfDocuments,
        externalIds: [],
        id: item.document_type_id,
        isDisable,
      };
    });

  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentTypes>(initialDocumentTypes);
  const { mutateAsync: categorizeDocuments, isPending } = useCategorizeDocuments(opportunityId, applicantId);

  const updateSelectedType = (
    currentId: number | string,
    previousId: number | string,
    externalId: string
  ) => {
    setSelectedDocumentType(
      selectedDocumentType.map((item) => {
        if (shouldUpdateSelectType(currentId, item.id, item.name)) {
          const count = item.count + 1;
          return {
            ...item,
            count,
            externalIds: [...item.externalIds, externalId],
            isDisable: count >= item.maxFiles,
          };
        }
        if (shouldUpdateSelectType(previousId, item.id, item.name)) {
          const count = item.count > 0 ? item.count - 1 : 0;
          return {
            ...item,
            count,
            externalIds: item.externalIds.filter((id) => id !== externalId),
            isDisable: count >= item.maxFiles,
          };
        }
        return item;
      })
    );
  };

  const closeModalOnDelete = () => {
    if (savedFiles.length === 1) {
      goNext();
    }
  };

  if (isLoading) {
    return <OrganizeUploadLoader />;
  }

  return (
    <>
      <Box className={ categorizeBulkUploadBody }>
        <Stack gap={ theme.spacing.sm } align='start'>
          <Group mb='lg'>
            <Box
              p='md'
              style={ {
                background: 'rgba(158, 137, 195, 1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px',
              } }
            >
              <IconFiles color='white' />
            </Box>
            <Stack gap='xs'>
              <Text size='2xl' fontWeight='bold'>
                {t('documents.bulkUpload.categorizeDocuments.title')}
              </Text>
              <Text size='md' color='gray.9'>
                {t('documents.bulkUpload.categorizeDocuments.description')}
              </Text>
            </Stack>
          </Group>
          <Box mb='lg' w='100%'>
            {savedFiles.length > 0 && (
              <Text color='gray.9' mb='2!'>
                {t(
                  'documents.bulkUpload.categorizeDocuments.numberOfDocument',
                  { count: savedFiles.length }
                )}
              </Text>
            )}
            {savedFiles.map((doc) => (
              <CategorizeSingleDocument
                key={ doc.opportunity_applicant_document_id }
                file={ doc }
                documentsType={ documentsType }
                opportunityId={ opportunityId }
                applicantId={ applicantId }
                selectedDocumentType={ selectedDocumentType }
                updateSelectedType={ updateSelectedType }
                closeModalOnDelete={ closeModalOnDelete }
              />
            ))}
          </Box>
        </Stack>
      </Box>
      <Group
        bg='gray.0'
        align='center'
        justify='end'
        w='100%'
        style={ { padding: '16px' } }
        className={ footerContainer }
      >
        <Button
          px='4!'
          data-test='confirm-btn'
          onClick={ async () => {
            const documents = selectedDocumentType.reduce(
              (acc: SingleCategorizeDocument[], item) => {
                item.externalIds.forEach((externalId) => {
                  const data = item.id
                    ? {
                      document_type_id: item.id,
                      applicant_document_external_id: externalId,
                    }
                    : {
                      document_type_name: item.name,
                      applicant_document_external_id: externalId,
                    };
                  acc.push(data);
                });
                return acc;
              },
              []
            );
            if (documents.length > 0) {
              await categorizeDocuments({ documents });
            }
            goNext();
          } }
          disabled={ isPending }
        >
          {isPending ? (
            <Loader color='black' size={ 20 } />
          ) : (
            t('documents.bulkUpload.categorizeDocuments.confirmButton')
          )}
        </Button>
      </Group>
    </>
  );
};
