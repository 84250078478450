import { theme } from '@huspy/forge';
import {
  Box,
  Progress, Space, Stack, Text
} from '@mantine/core';
import Loader from '@shared/loader';
import { useTranslation } from 'react-i18next';
import { documentUploadModalStyles } from '../DocumentUploadModal/styles/index.css';

const { body } = documentUploadModalStyles;

type LoaderType = {
  numOfDocs: number;
  numOfUploadedDoc: number;
};
export const UploadLoader = ({ numOfDocs, numOfUploadedDoc }: LoaderType) => {
  const { t } = useTranslation();
  return (
    <Box className={ body }>
      <Stack gap={ theme.spacing.sm } align='center'>
        <Space h='56px' />
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fw='600'>
          {t('documents.bulkUpload.loader.uploadingLoader', {
            uploadedDoc: numOfUploadedDoc + 1,
            totalUploadedDoc: numOfDocs,
          })}
        </Text>
        <Progress
          value={ ((numOfUploadedDoc + 1) / numOfDocs) * 100 }
          color='dark.9'
          w={ { base: '100%', sm: '50%' } }
          mt='xl'
        />
        <Space h='56px' />
      </Stack>
    </Box>
  );
};

export const ValidateUploadLoader = () => {
  const { t } = useTranslation();
  return (
    <Box className={ body }>
      <Stack gap={ theme.spacing.sm } align='center'>
        <Space h='56px' />
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fw='600'>
          {t('documents.bulkUpload.loader.validatingLoader')}
        </Text>
        <Space h='56px' />
      </Stack>
    </Box>
  );
};

export const OrganizeUploadLoader = () => {
  const { t } = useTranslation();
  return (
    <Box className={ body }>
      <Stack gap={ theme.spacing.sm } align='center'>
        <Space h='56px' />
        <Loader color='black' size={ 50 } />
        <Text size='xl' mb='sm' fw='600'>
          {t('documents.bulkUpload.loader.finalLoaderTitle')}
        </Text>
        <Text
          size='lg'
          mb='md'
          c='gray.6'
          w={ { base: '100%', sm: '50%' } }
          ta='center'
        >
          {t('documents.bulkUpload.loader.finalLoaderDescription')}
        </Text>
        <Space h='56px' />
      </Stack>
    </Box>
  );
};
