import {
  Box, Flex, Skeleton, rem
} from '@mantine/core';
import { useAuthStore } from '@modules/core/store';
import {
  Navigate, Outlet, useMatch, useLocation,
} from 'react-router-dom';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import { ErrorBoundary } from '@shared/errorBoundary';
import Header from '@modules/core/components/Header';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import { useTranslation } from 'react-i18next';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import { useEffect } from 'react';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import useGetVaultFeatureFlags from '@modules/core/hooks/queries/useGetVaultFeatureFlags';
import { layoutStyles } from './styles/index.css';

type Props = {
  headerTitlePath: string;
  subTitlePath: string;
  headerClassName?: string;
};

const pages = ['/documents', '/kyc'] as const;

const {
  innerBoxStyles,
  lowerSectionStyles,
  root,
  sectionsPadding,
} = layoutStyles;

const getBackArrowLink = (path: string) => {
  if (pages.includes(path as typeof pages[number])) {
    return '/';
  }
  if (path.includes(pages[0]!)) {
    return '/documents';
  }
  if (path.includes(pages[1]!)) {
    return '/kyc';
  }
};

const Layout = ({ headerTitlePath, subTitlePath, headerClassName }: Props) => {
  const { token, oppId } = useAuthStore();
  const {
    isLoading,
    data,
  } = useVaultData(oppId);
  const { isDisabled: isKycDisabled } = useKYCData(oppId);
  const { tokenFromSessionStorage } = usePersistAuth();
  const isHomePage = useMatch('/');
  const { t } = useTranslation();
  const location = useLocation();
  useHandleLanguage();
  useGetVaultFeatureFlags();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!tokenFromSessionStorage && !token) {
    return <Navigate to={ `/login/${oppId ?? ''}` } />;
  }

  if (location.pathname.includes('/kyc') && isKycDisabled) {
    return <Navigate to='/' />;
  }

  const homeHeaderTitle = (
    <>
      {t('home.header.title')}
      {isLoading
        ? (<Skeleton display='inline-block' h={ rem(15) } w={ rem(100) } />) : data.mainApplicant.firstName}
      {' '}
      👋
    </>
  );
  return (
    <Box h='100%'>
      <Box h='100%' className={ root }>
        <Flex h='100%' direction='column' className={ innerBoxStyles }>
          <Header
            title={ isHomePage ? homeHeaderTitle : t(headerTitlePath) }
            subtitle={ t(subTitlePath) }
            backArrowLink={ getBackArrowLink(location.pathname) }
            wrapperClassName={ headerClassName }
            { ...((pages.some((path) => location.pathname.includes(path)) && { isCompact: true })) }
          />
          <Flex className={ lowerSectionStyles } w='100%'>
            <Flex className={ sectionsPadding } w='100%'>
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Layout;
