import {
  Button,
  Input, Select, Text, theme,
} from '@huspy/forge';
import {
  Box, Flex, Group, Image, SimpleGrid, Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import Chip from '@modules/core/components/Chip';
import { iconsPath, minMd } from '@shared/css.const';
import { z } from 'zod';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useAuthStore } from '@modules/core/store';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import {
  useMatch, useNavigate, useOutletContext
} from 'react-router-dom';
import { ResidencyKYCData } from '@modules/kyc/api/types';
import { requiredErrorMessage, requiredFieldError } from '@shared/validators';
import { useEffect } from 'react';
import useUpdateKYCData from '@modules/kyc/hooks/mutations/updateKYCData';
import { retrieveFormErrors } from '@modules/kyc/utils';
import { countriesJSON as jsonCountries } from '@huspy/forge/lib';
import { APPLICANT_TYPE } from '@modules/documents/api/const';
import { CITIZEN_STATUS } from '@modules/kyc/api/const';
import { IconArrowRight } from '@tabler/icons-react';
import { t } from 'i18next';
import { useMediaQuery } from '@mantine/hooks';
import Loader from '@shared/loader';
import { validators } from '@huspy/forge/shared';
import { kycCategoryStyles } from '../styles/index.css';

const { numberFieldValidator, textFieldValidator } = validators;

const schema = (isNotNonResident?: boolean) => z.object({
  ...(isNotNonResident && {
    status_of_current_residence: z.enum(['rented', 'owned']),
    no_of_months_lived_in_current_residence: numberFieldValidator.max(500),
    year_moved_to_country: numberFieldValidator.max(3000),
    address: z.object({
      city: textFieldValidator.max(40),
      street: textFieldValidator,
      state_code: textFieldValidator,
      country_code: textFieldValidator,
    }),
    po_box: z.string({ ...requiredErrorMessage }).min(1, { message: requiredFieldError }).max(50),
  }),
  mobile_no_in_home_country: textFieldValidator,
  reference: z.object({
    ...(isNotNonResident && {
      name_of_first_friend_or_relative_in_the_purchasing_country: textFieldValidator,
      mobile_no_of_first_friend_or_relative_in_the_purchasing_country: textFieldValidator,
      name_of_second_friend_or_relative_in_the_purchasing_country: textFieldValidator,
      mobile_no_of_sec_friend_or_relative_in_the_purchasing_country: textFieldValidator,
    }),
    name_of_first_friend_or_relative_in_the_home_country: textFieldValidator,
    mobile_no_of_first_friend_or_relative_in_home_country: textFieldValidator,
    name_of_second_friend_or_relative_in_home_country: textFieldValidator,
    mobile_no_of_second_friend_or_relative_in_home_country: textFieldValidator,
  }),
  address_in_home_country: z.object(
    {
      street: textFieldValidator,
      city: textFieldValidator.max(40),
      postal_code: textFieldValidator,
      country_code: textFieldValidator,
    }
  ),
});

const initialAddress = {
  city: '',
  state_code: '',
  street: '',
  country_code: 'AE',
};

const initialHomeAddress = {
  city: '',
  country_code: '',
  postal_code: '',
  street: '',
};

const getFormFields = (data?: ResidencyKYCData) => ({
  status_of_current_residence: data?.status_of_current_residence ?? '',
  address: data?.address ? {
    ...data.address,
    country_code: initialAddress.country_code,
  } : initialAddress,
  po_box: data?.po_box ?? '',
  no_of_months_lived_in_current_residence: data?.no_of_months_lived_in_current_residence
    ? data.no_of_months_lived_in_current_residence / 12 : null,
  year_moved_to_country: data?.year_moved_to_country ?? null,
  address_in_home_country: data?.address_in_home_country ?? initialHomeAddress,
  mobile_no_in_home_country: data?.mobile_no_in_home_country ?? '',
  reference:
    {
      mobile_no_of_first_friend_or_relative_in_home_country:
        data?.reference?.mobile_no_of_first_friend_or_relative_in_home_country ?? '',
      mobile_no_of_first_friend_or_relative_in_the_purchasing_country:
        data?.reference?.mobile_no_of_first_friend_or_relative_in_the_purchasing_country ?? '',
      mobile_no_of_sec_friend_or_relative_in_the_purchasing_country:
        data?.reference?.mobile_no_of_sec_friend_or_relative_in_the_purchasing_country ?? '',
      mobile_no_of_second_friend_or_relative_in_home_country:
        data?.reference?.mobile_no_of_second_friend_or_relative_in_home_country ?? '',
      name_of_first_friend_or_relative_in_the_home_country:
        data?.reference?.name_of_first_friend_or_relative_in_the_home_country ?? '',
      name_of_first_friend_or_relative_in_the_purchasing_country:
        data?.reference?.name_of_first_friend_or_relative_in_the_purchasing_country ?? '',
      name_of_second_friend_or_relative_in_home_country:
        data?.reference?.name_of_second_friend_or_relative_in_home_country ?? '',
      name_of_second_friend_or_relative_in_the_purchasing_country:
        data?.reference?.name_of_second_friend_or_relative_in_the_purchasing_country ?? '',
    }
  ,
});

const STATES = jsonCountries.find((country: { alpha2: string }) => country.alpha2 === 'AE')
  .states.map((state: { name: string, stateCode: string }) => ({ label: state.name, value: state.stateCode }));

const KYCResidency = () => {
  const match = useMatch('/kyc/:category/:applicantId');
  const applicantId = match?.params.applicantId!;
  const { oppId } = useAuthStore();
  const { mutateAsync, error, isPending } = useUpdateKYCData(oppId!, applicantId);
  const { canSkipToTheNextStep } = useOutletContext<{ canSkipToTheNextStep(step: string): boolean }>();
  const isDesktop = useMediaQuery(minMd);
  const { data: { kyc } } = useKYCData(oppId);
  const applicant = kyc?.[match?.params.applicantId!];
  const residentialData = applicant?.residential_information;
  const personalData = applicant?.personal_information;
  const navigate = useNavigate();
  const isNotNonResident = personalData?.citizen_status !== CITIZEN_STATUS.nonResident;
  const form = useForm<ResidencyKYCData>({
    initialValues: getFormFields(),
    validate: zodResolver(schema(isNotNonResident)),
    validateInputOnChange: true,
  });
  const canSkipToNextStep = canSkipToTheNextStep('employment');

  const isDirty = form.isDirty();

  useEffect(() => {
    const formFields = getFormFields(residentialData);
    form.setValues(formFields);
    form.resetDirty(formFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentialData, isNotNonResident]);

  useEffect(() => {
    form.setErrors(retrieveFormErrors(error?.data.residential_information));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleUpdate = async (data: ResidencyKYCData) => {
    const countryName = jsonCountries.find(
      ({ alpha2 }: { alpha2: string }) => alpha2 === data.address_in_home_country?.country_code
    )?.name;

    const residential_information = isNotNonResident ? {
      ...data,
      no_of_months_lived_in_current_residence: data.no_of_months_lived_in_current_residence! * 12,
      address_in_home_country: {
        ...data.address_in_home_country,
        country_name: countryName,
      },
    } : {
      mobile_no_in_home_country: data.mobile_no_in_home_country,
      reference: {
        name_of_first_friend_or_relative_in_the_home_country:
          data.reference.name_of_first_friend_or_relative_in_the_home_country,
        mobile_no_of_first_friend_or_relative_in_home_country:
          data.reference.mobile_no_of_first_friend_or_relative_in_home_country,
        name_of_second_friend_or_relative_in_home_country:
          data.reference.name_of_second_friend_or_relative_in_home_country,
        mobile_no_of_second_friend_or_relative_in_home_country:
          data.reference.mobile_no_of_second_friend_or_relative_in_home_country,
      },
      address_in_home_country: {
        ...data.address_in_home_country,
        country_name: countryName,
      },
    };
    await mutateAsync({
      // @ts-ignore
      body: { residential_information },
      metadata: {
        sectionName: `${applicant?.applicant_type === APPLICANT_TYPE.mainApplicant
          ? '' : 'Co-Borrower'} Residency Details`,
      },
    });
    navigate(`/kyc/employment/${match?.params.applicantId}`);
  };

  return (
    <>
      <Stack gap={ theme.spacing['3xl'] }>
        { isNotNonResident && (
          <form onSubmit={ form.onSubmit(handleUpdate) }>
            <Box>
              <Text size='md' fw={ 600 }>Residency details in UAE</Text>
              <SimpleGrid
                cols={ { base: 1, md: 2 } }
                spacing={ theme.spacing.lg }
                verticalSpacing={ theme.spacing.xl }
                mt={ theme.spacing.xl }
              >
                <Input
                  label='In which year did you move to UAE?'
                  type='number'
                  noNumberSeparators
                  maxLength={ 4 }
                  data-test='input-year-moved-to-uae'
                  { ...form.getInputProps('year_moved_to_country') }
                />
                <Input
                  label='Address'
                  name='address'
                  data-test='input-residency-address'
                  { ...form.getInputProps('address.street') }
                />
                <Input
                  label='City'
                  name='city'
                  data-test='input-residency-city'
                  { ...form.getInputProps('address.city') }
                />
                <Select
                  label='State'
                  data={ STATES }
                  placeholder='Pick a state'
                  dropdownScroll
                  size='md'
                  data-test='select-state'
                  { ...form.getInputProps('address.state_code') }
                />
                <Input
                  label='PO Box'
                  name='po_box'
                  data-test='input-po-box'
                  { ...form.getInputProps('po_box') }
                />
                <Flex justify='center' align='center'>
                  <Input
                    label='How long are you living in your current address?'
                    type='number'
                    noNumberSeparators
                    maxLength={ 3 }
                    max={ 100 }
                    trail='years'
                    data-test='input-current-address-years-lived'
                    { ...form.getInputProps('no_of_months_lived_in_current_residence') }
                  />
                </Flex>
                <Box>
                  <Text size='sm' color='neutral.8' mb={ theme.spacing.sm }>Status of current residence</Text>
                  <Group gap={ theme.spacing.lg } w='100%' grow>
                    <Chip.Group { ...form.getInputProps('status_of_current_residence') } data-test='chip-residence-group'>
                      <Chip value='rented'>
                        <Image
                          src={ `${iconsPath}/rented.svg` }
                        />
                        <Text
                          mt={ theme.spacing.sm }
                          size='xs'
                          c='neutral.8'
                          fw={ 600 }
                          data-test='chip-residence-rented'
                        >
                          Rented
                        </Text>
                      </Chip>
                      <Chip value='owned'>
                        <Image
                          src={ `${iconsPath}/owned.svg` }
                        />
                        <Text
                          mt={ theme.spacing.sm }
                          size='xs'
                          c='neutral.8'
                          fw={ 600 }
                          data-test='chip-residence-owned'
                        >
                          Owned
                        </Text>
                      </Chip>
                    </Chip.Group>
                  </Group>
                  {form.errors?.status_of_current_residence
                      && <Text c='negative.1' fw={ 600 } size='xs' mt={ theme.spacing.xs }>{requiredFieldError}</Text>}
                </Box>
              </SimpleGrid>
              <Text size='sm' fw={ 600 } c='neutral.5' mt={ theme.spacing.lg }>References</Text>
              <SimpleGrid
                cols={ { base: 1, md: 2 } }
                spacing={ theme.spacing.lg }
                verticalSpacing={ theme.spacing.xl }
                mt={ theme.spacing.sm }
              >
                <Input
                  label='Name of friend/relative (1)'
                  inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                  data-test='input-name-of-friend-1'
                  { ...form.getInputProps('reference.name_of_first_friend_or_relative_in_the_purchasing_country') }
                />
                <Input
                  label='Mobile number of friend/relative (1)'
                  description='E.g. +971xx'
                  inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                  data-test='input-mobile-of-friend-1'
                  { ...form.getInputProps('reference.mobile_no_of_first_friend_or_relative_in_the_purchasing_country') }
                />
                <Input
                  label='Name of friend/relative (2)'
                  data-test='input-name-of-friend-2'
                  inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                  { ...form.getInputProps('reference.name_of_second_friend_or_relative_in_the_purchasing_country') }
                />
                <Input
                  label='Mobile number of friend/relative (2)'
                  description='E.g. +971xx'
                  inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                  data-test='input-mobile-of-friend-2'
                  { ...form.getInputProps('reference.mobile_no_of_sec_friend_or_relative_in_the_purchasing_country') }
                />
              </SimpleGrid>
            </Box>
          </form>
        ) }
        <form onSubmit={ form.onSubmit(handleUpdate) }>
          <Box>
            <Text size='md' fw={ 600 }>Home country residency details</Text>
            <SimpleGrid
              cols={ { base: 1, md: 2 } }
              spacing={ theme.spacing.lg }
              verticalSpacing={ theme.spacing.xl }
              mt={ theme.spacing.xl }
            >
              <Input
                label='Address'
                name='address'
                data-test='input-home-address'
                { ...form.getInputProps('address_in_home_country.street') }
              />
              <Input
                label='City'
                name='city'
                data-test='input-home-city'
                { ...form.getInputProps('address_in_home_country.city') }
              />
              <Select
                label='Country'
                data={ jsonCountries.map(
                  (country: { name: string, alpha2: string }) => ({ label: country.name, value: country.alpha2 })
                ) }
                placeholder='Pick a country'
                dropdownScroll
                allowSearch
                size='md'
                data-test='select-home-country'
                { ...form.getInputProps('address_in_home_country.country_code') }
              />
              <Input
                label='Postal / Zip'
                name='postal'
                data-test='input-home-zip'
                { ...form.getInputProps('address_in_home_country.postal_code') }
              />
              <Input
                label='Phone number'
                description='E.g. +971xx'
                name='mobile'
                data-test='input-home-mobile'
                { ...form.getInputProps('mobile_no_in_home_country') }
              />
            </SimpleGrid>
            <Text size='sm' fw={ 600 } c='neutral.5' mt={ theme.spacing.lg }>References</Text>
            <SimpleGrid
              cols={ { base: 1, md: 2 } }
              spacing={ theme.spacing.lg }
              verticalSpacing={ theme.spacing.xl }
              mt={ theme.spacing.sm }
            >
              <Input
                label='Name of friend/relative (1)'
                inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                data-test='input-home-name-of-friend-1'
                { ...form.getInputProps('reference.name_of_first_friend_or_relative_in_the_home_country') }
              />
              <Input
                label='Mobile number of friend/relative (1)'
                description='E.g. +971xx'
                inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                data-test='input-home-mobile-of-friend-1'
                { ...form.getInputProps('reference.mobile_no_of_first_friend_or_relative_in_home_country') }
              />
              <Input
                label='Name of friend/relative (2)'
                inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                data-test='input-home-name-of-friend-2'
                { ...form.getInputProps('reference.name_of_second_friend_or_relative_in_home_country') }
              />
              <Input
                label='Mobile number of friend/relative (2)'
                description='E.g. +971xx'
                inputWrapperOrder={ ['label', 'input', 'description', 'error'] }
                data-test='input-home-mobile-of-friend-2'
                { ...form.getInputProps('reference.mobile_no_of_second_friend_or_relative_in_home_country') }
              />
            </SimpleGrid>
          </Box>
          <Button
            type='submit'
            rightSection={ isPending
              ? (<Loader size={ 16 } />)
              : (<IconArrowRight size={ 20 } cursor='pointer' />) }
            size={ isDesktop ? 'md' : 'lg' }
            className={ kycCategoryStyles.advanceButton }
            disabled={ isPending || (!isDirty && !canSkipToNextStep) }
            onClick={ (e) => {
              if (!isDirty && canSkipToTheNextStep('employment')) {
                e.preventDefault();
                navigate(`/kyc/employment/${match?.params.applicantId}`);
              }
            } }
            data-test='btn-kyc-submit'
          >
            { t('kyc.employmentKyc.title') }
          </Button>
        </form>
      </Stack>
    </>
  );
};

export default KYCResidency;
